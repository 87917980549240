import { useState } from "react";

import github from "../../assets/logos/github_icon.png";
import html from "../../assets/logos/html5.png";
import css from "../../assets/logos/css.png";
import js from "../../assets/logos/javascript.png";
import react from "../../assets/logos/react.png";
import node from "../../assets/logos/nodejs.png";
import graphql from "../../assets/logos/gql.png";
import sass from "../../assets/logos/Sass.png";
import tailwind from "../../assets/logos/tailwind.png";
import materialui from "../../assets/logos/mui.png";
import mysql from "../../assets/logos/MySQL.png";
import postgresql from "../../assets/logos/postgresql.png";
import ts from "../../assets/logos/typescript.png";
import knex from "../../assets/logos/knex.png";
import jest from "../../assets/logos/jest.png";
import cypress from "../../assets/logos/cypress.png";
import npm from "../../assets/logos/npm.png";
import axios from "../../assets/logos/axios.png";
import jwt from "../../assets/logos/jwt.png";
import agile from "../../assets/logos/agile-methodologies.png";
import scrollArrow from "../../assets/icons/chevron_right-24px.svg";
import LinkBar from "../../components/LinkBar/LinkBar";
import "./Portfolio.scss";

function Portfolio() {
  const [scrollDisplay, setScrollDisplay] = useState(false);

  function scrollFunction(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  window.onscroll = function () {
    if (window.scrollY > 250) {
      setScrollDisplay(true);
    } else {
      setScrollDisplay(false);
    }
  };

  return (
    <main className="portfolio-container">
      <div className="portfolio">
        <button
          className={`portfolio__scroll ${
            scrollDisplay ? "portfolio__scroll-show" : ""
          }`}
          onClick={() => {
            scrollFunction();
          }}
        >
          Scroll to Top
          <img className="portfolio__arrow" src={scrollArrow} alt="" />
        </button>
        <LinkBar />
        <div className="portfolio__greeting">
          <img
            className="portfolio__avatar"
            src="https://i.imgur.com/eBoqyBZ.jpg"
            alt="Toronto and I"
          />
          <div className="portfolio__intro">
            <span>Hey there, I am</span>
            <h1>Moin Sekander</h1>
          </div>
        </div>
        <div className="portfolio__paras">
          <h2 className="portfolio__title">About Me</h2>
          <p className="portfolio__para">
            A firm believer in continuous learning and personal development, I
            have found an immense passion in coding.
          </p>
          <p className="portfolio__para">
            Coming from an engineering background, I am experienced in breaking
            down large problems into smaller, more palettable tasks to reach a
            clean and complete solution.
          </p>
          <p className="portfolio__para">
            I truly believe coding has the power to do amazing things. Super
            excited to see what's next for me and to grow in this industry!
          </p>
        </div>
        <div className="portfolio__skills">
          <h2 className="portfolio__title">Tech Stack</h2>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={js} alt="javascript logo" />
            <span className="portfolio__caption portfolio__caption--long">
              Javascript
            </span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={ts} alt="typescript logo" />
            <span className="portfolio__caption portfolio__caption--long">
              Typescript
            </span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={html} alt="html5 logo" />
            <span className="portfolio__caption">HTML</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={css} alt="css logo" />
            <span className="portfolio__caption">CSS</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={github} alt="github logo" />
            <span className="portfolio__caption">Github</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={react} alt="react logo" />
            <span className="portfolio__caption">React</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={npm} alt="npm logo" />
            <span className="portfolio__caption">Npm</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={node} alt="node.js logo" />
            <span className="portfolio__caption">Node.js</span>
          </div>
          <div className="portfolio__skill">
            <img
              className="portfolio__image"
              src={graphql}
              alt="graphql logo"
            />
            <span className="portfolio__caption">GraphQL</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={sass} alt="sass logo" />
            <span className="portfolio__caption">Sass</span>
          </div>
          <div className="portfolio__skill">
            <img
              className="portfolio__image"
              src={tailwind}
              alt="tailwind logo"
            />
            <span className="portfolio__caption">Tailwind</span>
          </div>
          <div className="portfolio__skill">
            <img
              className="portfolio__image"
              src={materialui}
              alt="materialui logo"
            />
            <span className="portfolio__caption">Material UI</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={mysql} alt="mysql logo" />
            <span className="portfolio__caption">MySQL</span>
          </div>
          <div className="portfolio__skill">
            <img
              className="portfolio__image"
              src={postgresql}
              alt="postgresql logo"
            />
            <span className="portfolio__caption">PostgreSQL</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={axios} alt="axios logo" />
            <span className="portfolio__caption">Axios</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={knex} alt="knex logo" />
            <span className="portfolio__caption">Knex</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={jwt} alt="jwt logo" />
            <span className="portfolio__caption">JWT</span>
          </div>
          <div className="portfolio__skill">
            <img className="portfolio__image" src={jest} alt="jest logo" />
            <span className="portfolio__caption">Jest</span>
          </div>
          <div className="portfolio__skill">
            <img
              className="portfolio__image"
              src={cypress}
              alt="cypress logo"
            />
            <span className="portfolio__caption">Cypress</span>
          </div>
          <div className="portfolio__skill">
            <img
              className="portfolio__image"
              src={agile}
              alt="agile methodology logo"
            />
            <span className="portfolio__caption">Agile</span>
          </div>
        </div>
        <div className="portfolio__projects">
          <h2 className="portfolio__title">Projects</h2>
          <div className="portfolio__showcase">
            <div className="portfolio__showcase-card">
              <h2 className="portfolio__subtitle portfolio__subtitle--hide">
                Showcase Project 1
              </h2>
              <img
                className="portfolio__showcase-image"
                src="https://i.imgur.com/ByC9Noq.png"
                alt="boards for less logo"
              />
              <div className="portfolio__showcase-description">
                <h2 className=" portfolio__subtitle-showcase">
                  Showcase Project 1
                </h2>
                <p className="portfolio__para">
                  “Boards For Less !” is an interactive desktop web application
                  that connects users looking to try new games with users that
                  are looking to rent them out. With autocomplete from Board
                  Game Atlas API and the map technology of Google Maps Platform
                  API, users can use the site seamlessly.
                </p>
                <div className="portfolio__showcase-actions">
                  <a
                    className="portfolio__showcase-cta"
                    href="https://youtube.com/watch?v=VIrHKbnix7M"
                  >
                    Demo
                  </a>
                  <a
                    className="portfolio__showcase-cta"
                    href="https://boards-for-less.netlify.app/"
                  >
                    Site
                  </a>
                  <a
                    className="portfolio__showcase-cta"
                    href="https://github.com/m-sekander/boards-for-less-client"
                  >
                    Code
                  </a>
                </div>
              </div>
            </div>
            <div className="portfolio__showcase-card">
              <h2 className="portfolio__subtitle portfolio__subtitle--hide">
                Showcase Project 2
              </h2>
              <img
                className="portfolio__showcase-image"
                src="https://i.imgur.com/ZM2jivq.png"
                alt="boards for less logo"
              />
              <div className="portfolio__showcase-description">
                <h2 className=" portfolio__subtitle-showcase">
                  Showcase Project 2
                </h2>
                <p className="portfolio__para">
                  "CityGuide" is your one-stop shop to plan your next vacation.
                  The app conveniently provides an autocomplete search bar, an
                  interactive map, upcoming weather data, and notable locations!
                </p>
                <div className="portfolio__showcase-actions">
                  <a
                    className="portfolio__showcase-cta"
                    href="https://moins-city-guide.netlify.app/home"
                  >
                    Site
                  </a>
                  <a
                    className="portfolio__showcase-cta"
                    href="https://github.com/m-sekander/city-guide"
                  >
                    Code
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="portfolio__bootcamp">
            <div className="portfolio__bootcamp-card">
              <h2 className="portfolio__subtitle">Bootcamp Project 1</h2>
              <img
                className="portfolio__bootcamp-image"
                src="https://i.imgur.com/AcfILgX.png"
                alt="boards for less logo"
              />
              <div className="portfolio__bootcamp-description">
                <p className="portfolio__para">
                  "In Stock" is a full stack application that manages inventory
                  for the various warehouses of a Fortune 500 company. Created
                  with 3 other bootcamp peers within a week, the goal of this
                  project was to practice Agile methodologies in order to
                  quickly and effectively develop the app.
                </p>
                <div className="portfolio__bootcamp-actions">
                  <a
                    className="portfolio__bootcamp-cta"
                    href="https://team-j22nk-instock.netlify.app/"
                  >
                    Site
                  </a>
                  <a
                    className="portfolio__bootcamp-cta"
                    href="https://github.com/m-sekander/j22nk-instock-client"
                  >
                    Code
                  </a>
                </div>
              </div>
            </div>
            <div className="portfolio__bootcamp-card">
              <h2 className="portfolio__subtitle">Bootcamp Project 2</h2>
              <img
                className="portfolio__bootcamp-image"
                src="https://i.imgur.com/63oAb9E.png"
                alt="boards for less logo"
              />
              <div className="portfolio__bootcamp-description">
                <p className="portfolio__para">
                  "BrainFlix" is a mock video sharing platform that allows the
                  user to view the catalogue of content as well as upload their
                  own. Next steps would be to implement video playback, search
                  and comment functions to the site.
                </p>
                <div className="portfolio__bootcamp-actions">
                  <a
                    className="portfolio__bootcamp-cta"
                    href="https://moins-brainflix.netlify.app"
                  >
                    Site
                  </a>
                  <a
                    className="portfolio__bootcamp-cta"
                    href="https://github.com/m-sekander/moinuddin-sekander-brainflix"
                  >
                    Code
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LinkBar footer={true} />
      </div>
    </main>
  );
}

export default Portfolio;
