import linkedIn from "../../assets/logos/LinkedIn_icon_circle.png";
import github from "../../assets/logos/github_icon.png";
import email from "../../assets/logos/computer-mail-logo-email.png";
import resume from "../../assets/logos/profile-logo.png";
import "./LinkBar.scss";

function LinkBar({ footer }) {
  return (
    <div className="link-bar__links">
      <a
        className="link-bar__link-container"
        href="https://www.linkedin.com/in/moinsekander/"
      >
        <p
          className={
            footer ? "link-bar__link-label--bottom" : "link-bar__link-label"
          }
        >
          LinkedIn
        </p>
        <img className="link-bar__link" src={linkedIn} alt="linkedIn profile" />
      </a>
      <a
        className="link-bar__link-container"
        href="https://github.com/m-sekander"
      >
        <p
          className={
            footer ? "link-bar__link-label--bottom" : "link-bar__link-label"
          }
        >
          GitHub
        </p>
        <div className="link-bar__link-background"></div>
        <img className="link-bar__link" src={github} alt="github profile" />
      </a>
      <a
        className="link-bar__link-container"
        href="mailto:moinuddin8510@gmail.com"
      >
        <p
          className={
            footer ? "link-bar__link-label--bottom" : "link-bar__link-label"
          }
        >
          Email
        </p>
        <img className="link-bar__link" src={email} alt="email link" />
      </a>
      <a
        className="link-bar__link-container"
        href="https://docs.google.com/document/d/13G_cYkaCf3TuZT0fPUeRx1KBrxJ9EiUT3ElkFbxDoyg/export?format=pdf"
      >
        <p
          className={
            footer ? "link-bar__link-label--bottom" : "link-bar__link-label"
          }
        >
          Resume
        </p>
        <img className="link-bar__link" src={resume} alt="resume link" />
      </a>
    </div>
  );
}
export default LinkBar;
